@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Akkurat';
    src: url('/typeface/AkkuratLLWeb-Black.woff2') format('woff2'),
      url('/typeface/AkkuratLLWeb-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Akkurat';
    src: url('/typeface/AkkuratLLWeb-Bold.woff2') format('woff2'),
      url('/typeface/AkkuratLLWeb-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Akkurat';
    src: url('/typeface/AkkuratLLWeb-Regular.woff2') format('woff2'),
      url('/typeface/AkkuratLLWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'BuenosAires';
    src: url('/typeface/BuenosAires_Variable_Upright_Web.woff2')
        format('woff2 supports variations'),
      url('/typeface/BuenosAires_Variable_Upright_Web.woff2')
        format('woff2-variations');
    font-weight: 100 1000;
    font-stretch: 25% 151%;
  }
}

@layer components {
  .btn-blue {
    @apply px-4 py-2 font-bold text-white bg-blue-500 rounded;
  }
}

.mono {
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
}
html {
  font-size: 16px;
}

html,
body {
  overscroll-behavior: none;

  -moz-font-feature-settings: 'kern' 1;
  -ms-font-feature-settings: 'kern' 1;
  -o-font-feature-settings: 'kern' 1;
  -webkit-font-feature-settings: 'kern' 1;
  font-feature-settings: 'kern' 1;
  text-rendering: optimizeLegibility; /* emphasise on legibility when rendering, turns on ligatures and kerning */
  -webkit-font-smoothing: antialiased; /* apply font anti-aliasing for Webkit on OSX */
  -moz-osx-font-smoothing: grayscale; /* apply font anti-aliasing for Firefox on OSX */
  font-feature-settings: 'ss01' on;

  /* font-kerning: normal; */
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
  /* letter-spacing: 0.015em; */
  /* word-spacing: 0.001em; */
}

[data-reach-combobox-popover] {
  /* border: none !important; */
  /* font-size: 100% !important; */
  background: transparent;
  margin-top: 0.25rem;
}

/* [data-reach-combobox-popover] {
  border: solid 1px hsla(0, 0%, 0%, 0.25);
  background: hsla(0, 100%, 100%, 0.99);
  font-size: 85%;
} */

[data-reach-combobox-list] {
  list-style: none;
  margin: 0;
  padding: 0;
  user-select: none;
}

[data-reach-combobox-option] {
  cursor: pointer;
  /* margin: 0; */
  /* padding: 0.25rem 0.5rem; */
}

[data-reach-combobox-option][aria-selected='true'] {
  /* background: hsl(211, 10%, 95%); */
  @apply bg-blue-50 bg-opacity-50;
}

[data-reach-combobox-option]:hover {
  /* background: hsl(211, 10%, 92%); */
}

[data-reach-combobox-option][aria-selected='true']:hover {
  /* background: hsl(211, 10%, 90%); */
  @apply bg-blue-50;
}

[data-suggested-value] {
  /* font-weight: bold;
  text-decoration: underline; */
}

/* Emoji Picker */
.emoji-mart {
  font-family: AkkuratLL, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
  border: none !important;
  @apply shadow;
}
.emoji-mart-bar {
  display: none;
}

.emoji-mart-category-label {
  font-size: 0.75em;
  margin-top: 0.25em;
}
.emoji-mart-search-icon {
  top: 6px !important;
}
.emoji-mart-search > input {
  font-size: 0.75em !important;
}

/* Radix ui animations */

@keyframes showTooltipTop {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hideTooltipTop {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

.tooltip-content-top[data-state='delayed-open'] {
  animation: showTooltipTop 180ms ease-in;
}

.tooltip-content-top[data-state='closed'] {
  animation: hideTooltipTop 180ms ease-in;
}
